<template>
  <v-dialog
    v-model="dialog"
    class="dialog font-weight-medium"
    max-width="600"
    persistent
  >
    <v-card class="card py-10">
      <h3 class="text-left font-family-raleway-bold">
        {{ plan.planName }} {{ $t("create_campaign_page.plan_selected") }}
      </h3>
      <p class="my-5 font-raleway-regular">
        {{ $t("create_campaign_page.will_be_billed") }}
        <strong>${{ plan.planAmount }}</strong>
        {{
          plan.planType === "annualFee"
            ? $t("create_campaign_page.plan_type_annual_text")
            : $t("create_campaign_page.plan_type_monthly_text")
        }}
      </p>
      <v-row class="mt-7">
        <v-col class="text-center" md="6">
          <v-btn
            style="background-color: white; box-shadow: none"
            @click="$emit('closeModal')"
            class="font-family-raleway-medium text-decoration-underline mt-2 text-capitalize"
            >{{ $t("back_button") }}
          </v-btn>
        </v-col>
        <v-col class="text-center" md="6">
          <v-btn
            class="button font-weight-medium"
            @click="confirmChosenPlan"
            height="60"
            width="230"
            >{{ $t("create_campaign_page.confirm_button") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "MakeSureChosenPlan",
  props: {
    dialog: Boolean,
    plan: Object,
  },
  computed: {
    ...mapGetters["getUser"],
  },
  methods: {
    async confirmChosenPlan() {
      const campaign = localStorage.getItem("campaign");
      const parseToObj = JSON.parse(campaign);
      const { planId, planType } = this.plan;
      localStorage.setItem(
        "campaign",
        JSON.stringify({
          ...parseToObj,
          planId,
          planType,
          isPlanChoosingFirst: true,
        })
      );
      await this.$router.push("set-budget-payment");
    },
  },
};
</script>
